import { ClientRenderMode, RenderMode, RenderModeOnSubmitParams } from "clients/types";
import { ParentFrame } from "clients/types";
import { connectToParent } from "penpal";
export class IFrameRenderMode implements ClientRenderMode {
  public renderMode: RenderMode;
  public appWrapperClass: string;
  public onFormSubmit: ({ authCode, returnScheme }: RenderModeOnSubmitParams) => void;
  public minimizeFormContent: boolean;

  constructor ({
    clientUri,
    isFormStyled,
  }: {
    clientUri: string;
    isFormStyled: boolean;
  }) {
    this.renderMode = 'iframe';
    this.appWrapperClass = `App__iframe${isFormStyled ? ' styled' : ''}`;
    this.minimizeFormContent = false;

    this.onFormSubmit = ({ authCode }) => {
      const connection = connectToParent<ParentFrame>({
        parentOrigin: process.env.REACT_APP_WEB_HOST_URI,
      }); 
      connection.promise.then(parent => parent.redirect(authCode));
    }
  }
}
