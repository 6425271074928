import React from "react";
import styles from "./LoadingAnimation.module.scss";

interface LoadingAnimationProps {
  size?: string;
}
const LoadingAnimation: React.FC<LoadingAnimationProps> = ({ size }) => (
  <div
    className={`${styles["sk-fade-in-quarter-second"]} ${styles["sk-spinner"]} ${styles["sk-double-bounce"]} ${styles[size]}`}
  >
    <div></div>
    <div></div>
  </div>
);

export default LoadingAnimation;
