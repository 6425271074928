import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./LoginInput.module.scss";
import { ReactComponent as WorkIcon } from "../../assets/icons/work.svg";

interface SelectListProps {
  label?: string;
  type: string;
  id: string;
  placeholder?: string;
  value: string | number | string[] | undefined;
  error?: string;
  horizontal?: boolean;
  readonly?: boolean;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  column?: boolean;
  containerClass?: string;
  optionsString?: string;
}

export const SelectList: React.FC<SelectListProps> = ({
  label,
  type,
  id,
  placeholder,
  value,
  error,
  horizontal,
  readonly,
  disabled = false,
  onChange,
  column,
  containerClass,
  optionsString
}: SelectListProps) => {
  const { t, i18n } = useTranslation();
  const [options] = useState<{ value: string, text: string }[]>(t(optionsString, { returnObjects: true }));
  const [isFocused, setIsFocused] = useState(false);
  return (
    <>
      <div className={`form-group ${horizontal ? "row" : ""} ${column ? "col" : ""} ${styles['login-input']}`}>
        <div className={`${horizontal ? "col-xl-10" : ""}`}>
          <div className={`${containerClass} ${styles['input-container']} ${error ? styles['input-container--error'] : ""}`}>
            <label
              className={`${horizontal ? "col-xl-2 col-form-label" : ""} ${styles['floating-label']} ${!!value || isFocused ? styles['has-focus'] : ''}`}
              htmlFor={id}
            >
              {label}
            </label>
            <WorkIcon className={styles['work-icon']} />
            <select
              id={id}
              className={`form-control ${styles["select-list"]}`}
              onChange={onChange}
              defaultValue={"default"}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
            >
              <option value="default" hidden disabled></option>
              {options.map((item, i) => {
                return (
                  <option key={i + "-" + i18n.language.slice(0, 2).toLowerCase()} value={item.value} >
                    {item.text}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <small
          id={`${id}-help`}
          aria-describedby={id}
          className="form-text text-danger"
        >
          {error}
        </small>
      </div>
    </>
  );
};
