export interface CouponPromptProps {
  onClick: () => void;
}

export const CouponPrompt: React.FC<CouponPromptProps> = ({ onClick }) => {
  return (
    <span>
      <p>Have a coupon code?</p>
      <p onClick={onClick}>Redeem it now</p>
    </span>
  );
};
