import React, { useEffect, useState } from "react";
import styles from "./LoginInput.module.scss";
import { ReactComponent as ProfileIcon } from "assets/icons/username.svg";
import { ReactComponent as LockIcon } from "assets/icons/password.svg";

interface LoginInputProps {
  label?: string;
  type: "firstName" | "lastName" | "email" | "password";
  id: string;
  value: string | number | string[] | undefined;
  error?: string;
  horizontal?: boolean;
  column?: boolean;
  containerClass?: string;
  readonly?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: any;
}

export const LoginInput: React.FC<LoginInputProps> = ({
  label,
  type,
  id,
  value,
  error,
  horizontal,
  readonly,
  onChange,
  onFocus,
  containerClass,
  column
}: LoginInputProps) => {
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (onFocus && isFocused) {
      onFocus()
    }
  }, [isFocused, onFocus])

  return (
    <>
      <div className={`form-group ${horizontal ? "row" : ""} ${column ? "col" : ""} ${styles['login-input']}`}>
        <div className={`${horizontal ? "col-xl-10" : ""}`}>
          <div className={`${containerClass} ${styles['input-container']} ${error ? styles['input-container--error'] : ""}`}>
            <label
              className={`${horizontal ? "col-xl-2 col-form-label" : ""} ${styles['floating-label']} ${!!value || isFocused ? styles['has-focus'] : ''}`}
              htmlFor={id}
            >
              {label}
            </label>
            {type === "password"
              ? <LockIcon className={styles["lock-icon"]} />
              : <ProfileIcon className={styles["profile-icon"]} />
            }
            <input
              type={type}
              className="form-control"
              id={id}
              value={value || ""}
              onChange={onChange}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              readOnly={readonly}
            />
          </div>
        </div>
        <small
          id={`${id}-help`}
          aria-describedby={id}
          className="form-text text-danger"
        >
          {error}
        </small>
      </div>
    </>
  );
};
