import React, { useCallback, useEffect, useState } from "react";
import { LoginInput } from "components/text-input/LoginInput";
import { CouponInput } from "components/text-input/CouponInput";
import { Link, useCurrentRoute } from "react-navi";
import { useTranslation } from "react-i18next";
import { AccountCreationProps } from "./types";
import { Banner } from "components/banner/Banner";
import { SelectList } from "../text-input/SelectList";
import { TextInput } from "../text-input/TextInput";
import { useGetHostClient } from "hooks/useGetHostClient";
import { LogoAndPrompt } from "./shared/LogoAndPrompt";
import { PasswordRulesTooltip } from "components/tooltips/PasswordRulesTooltip";

import { CouponPrompt } from "components/prompts/CouponPrompt";
import { Checkbox } from "./Checkbox";
import { PhoneNumberInput } from "components/text-input/PhoneNumberInput";

const enableShopifyCoupons = process.env.REACT_APP_ENABLE_SHOPIFY_COUPONS === "true"

export const AccountCreationForm: React.FC<AccountCreationProps> = ({
  handleSubmit,
  handleChange,
  handleCouponCode,
  removeCoupon,
  isSubmitting,
  showLoginBtn,
  resellerInventoryItem,
  referrer,
  values,
  errors,
  passwordValidMap,
  containerClass,
  styles,
  formType,
}) => {
  const { t } = useTranslation();
  const { url } = useCurrentRoute();
  const { hostClient } = useGetHostClient();
  const [displayTooltip, setDisplayTooltip] = useState<boolean>(false);
  const [showTooltipArrowToRight, setShowTooltipArrowToRight] = useState<boolean>(false);

  const [ showCouponInput, setShowCouponInput ] = useState<boolean>(false);
  const [termsChecked, setTermsChecked] = useState<boolean>(false);
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);

  const couponInputIsAvailable = useCallback((): boolean => {
    if (referrer) return false;

    if (formType === "APP") return false;

    if (hostClient.clientId === "ShopifyApp" && !enableShopifyCoupons) return false;

    return true;
  }, [referrer, formType, hostClient.clientId])

  useEffect(() => {
    if (isSubmitting || !termsChecked) {
      setSubmitDisabled(true);
    } else {
      setSubmitDisabled(false);
    }
  }, [isSubmitting, termsChecked])

  return (
    <div className={styles["content-wrapper"]}>
      <div className={styles["form-wrapper"]}>
          {referrer &&
            <Banner header={`${t("banner:referred-by")} ${referrer.firstName} ${referrer.lastName}`} subheader={t('banner:referral-banner-subheader')} />
          }
          {hostClient.formIsStyled && (
            <LogoAndPrompt prompt="create-account:create-account-prompt" />
          )}
          <form onSubmit={handleSubmit} className={styles["login-form"]} id={styles["create-account-form"]}>
            <div className="row">
              <LoginInput
                label={t("create-account:first-name")}
                type="firstName"
                id="firstName"
                value={values.firstName}
                error={errors.firstName}
                onChange={handleChange}
                column={true}
                containerClass={containerClass || ''}
              />
              <LoginInput
                label={t("create-account:last-name")}
                type="lastName"
                id="lastName"
                value={values.lastName}
                error={errors.lastName}
                onChange={handleChange}
                column={true}
                containerClass={containerClass || ''}
              />
            </div>
            <LoginInput
              label={t("create-account:email-address")}
              type="email"
              id="email"
              value={values.email}
              error={errors.email}
              onChange={handleChange}
            />
            <PhoneNumberInput
                id="phoneNumber"
                placeholder={t("create-account:phone-number")}
                value={values.phoneNumber}
                error={errors.phoneNumber}
                onChange={handleChange}
                label=""
            />
            <div onFocus={() => setDisplayTooltip(true)} onBlur={() => setDisplayTooltip(false)} className="row">
              <LoginInput
                label={t("create-account:create-password")}
                type="password"
                id="password"
                value={values.password}
                error={errors.password}
                onChange={handleChange}
                onFocus={() => setShowTooltipArrowToRight(false)}
                column={true}
              />
              <LoginInput
                label={t("create-account:confirm-password")}
                type="password"
                id="confirmPassword"
                value={values.confirmPassword}
                error={errors.confirmPassword}
                onChange={handleChange}
                onFocus={() => setShowTooltipArrowToRight(true)}
                column={true}
              />
            </div>

            <PasswordRulesTooltip 
              t={t} 
              passwordValidMap={passwordValidMap} 
              display={displayTooltip}
              toRight={showTooltipArrowToRight}
            />

            {!hostClient.minimizeFormContent && (
              <>
                <SelectList
                  label={t("personalize-account:please-select")}
                  type="select"
                  id="industry"
                  value={values.industry}
                  error={errors.industry}
                  onChange={handleChange}
                  column={true}
                  optionsString="personalize-account:options"
                />
                {values.industry === "Other (Please Specify)" &&
                  <TextInput
                    label={t("personalize-account:placeholder")}
                    id="industryCustom"
                    type="text"
                    value={values.industryCustom}
                    error={errors.industryCustom}
                    onChange={handleChange}
                  />
                }
              </>
            )}
            {!showCouponInput && (
              <Checkbox
                description={t("create-account:checkbox-terms-1")}
                checked={termsChecked}
                onCheck={setTermsChecked}
              />
            )}
            <div className={!showCouponInput && couponInputIsAvailable() ? styles["submit-wrapper"] : undefined}>
            {
              showCouponInput && couponInputIsAvailable() &&
              <>
                <CouponInput
                  label={t("create-account:add-coupon")}
                  type="coupon"
                  id="coupon"
                  couponCode={resellerInventoryItem.CouponCode}
                  addCoupon={handleCouponCode}
                  removeCoupon={removeCoupon}
                />
                <Checkbox
                  description={t("create-account:checkbox-terms-1")}
                  checked={termsChecked} 
                  onCheck={setTermsChecked}
                />
              </>
            }
              {!showCouponInput && couponInputIsAvailable() && <CouponPrompt onClick={() => setShowCouponInput(true)} />}
              <button
                type="submit"
                className="btn btn-white md-btn btn-block mb-4"
                disabled={submitDisabled}
                >
                {t("buttons:create-account").toUpperCase()}
              </button>
            </div>
          </form>
          <div className={`text-center ${styles["legal-text"]}`}>
            {showLoginBtn &&
              <p className={styles["login-text"]}>
                {t("create-account:already-have-account")}{" "}
                <Link href={`/login${url.search}`} className={styles["login-links"]}>
                  {t("buttons:login").toUpperCase()}
                </Link>
              </p>
            }
            {hostClient.formIsStyled
            ? (
              <>
                <p>
                  {t("create-account:terms-and-privacy-1")}
                  {" "}
                  <Link
                    className={`text-center ${styles["login-links"]}`}
                    target="_blank"
                    href="https://www.switcherstudio.com/terms-of-use-app"
                  >
                    {t("create-account:terms-and-privacy-2")}
                  </Link>
                  {" "}
                  {t("create-account:terms-and-privacy-3")}
                  {" "}
                  <Link
                    className={`text-center ${styles["login-links"]}`}
                    target="_blank"
                    href="https://www.switcherstudio.com/privacy-policy-app"
                  >
                    {t("create-account:terms-and-privacy-4")}
                  </Link>
                </p>
              </>
            )
            : (
                <>
                  <p>{t("create-account:terms-1")}&nbsp;
                    <Link
                      className={`text-center ${styles["login-links"]}`}
                      target="_blank"
                      href="https://www.switcherstudio.com/terms-of-use-app"
                    >
                      {t("create-account:terms-2").toUpperCase()}
                    </Link>
                  </p>
                  <p>
                    {t("create-account:privacy-1")}{" "}
                    <Link
                      className={`text-center ${styles["login-links"]}`}
                      target="_blank"
                      href="https://www.switcherstudio.com/privacy-policy-app"
                    >
                      {t("create-account:privacy-2").toUpperCase()}
                    </Link>
                  </p>
                </>
            )}
          </div>
        </div>
      </div>
  );
};
