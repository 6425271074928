import { useEffect, useState } from "react";
import { client } from "api/client";
import { ReferrerResponse } from "@switcherstudio/switcher-api-client";

export function useGetReferrer(): { loadingReferrer: boolean, referrer: ReferrerResponse} {
  const [ loadingReferrer, setLoadingReferrer ] = useState<boolean>(true);
  const [ referrer, setReferrer ] = useState<ReferrerResponse>();
  const [ urlParams ] = useState<URLSearchParams>( new URLSearchParams(window.location.search) );
  const [ campaignId ] = useState<string | undefined>(urlParams.get('campaignId'));
  const [ referrerId ] = useState<string | undefined>(urlParams.get('referrerId'));

  useEffect(() => {
    const run = async () => {
      if ( campaignId && referrerId ) {
        setReferrer( 
          await client.growsurf_GetReferrer( campaignId, referrerId )
        );
        setLoadingReferrer(false)
      }
      setLoadingReferrer(false);
    }
    run();

  }, [ campaignId, referrerId, setReferrer ])

  return { loadingReferrer, referrer };
}