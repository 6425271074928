import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoadingState } from "./types";

export const loading = createSlice({
  name: "loading",
  initialState: {
    loading: 0,
  } as LoadingState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<number>) => {
      state.loading += payload;
    },
  }
});
export const { setLoading } = loading.actions;

export default loading.reducer;
