import { withTrackedView } from "helpers/analyticsHelpers";
import { route, compose, mount, redirect } from "navi";
import { View } from "react-navi";
import Layout from "./Layout";
import AccountCreationPage from "components/AccountCreation/AccountCreationPage";
import { LoginPage } from "components/Login/LoginPage";
import { ForgotPasswordPage } from "components/ForgotPassword/ForgotPassword";
import AccountBlockOldAppPage from "components/AccountBlockOldApp/AccountBlockOldAppPage";

export const routes = compose(
  mount({
    "/create-account": withTrackedView(
      <Layout>
        <View />
      </Layout>,
      route((req) => {

        const {clientId, location} = req.params;

        const checkForRedirect = (clientId: string, location: string) => {
          if (!clientId) {
            if (location !== "app") {
              return true;
            }
          }

          if (clientId !== "Switcher" && clientId !== "ShopifyApp" ) {
            return true;
          }

          return false;
        }

        const isIosVersionPriorTo16 = (userAgent) => {
          const match = userAgent.match(/(iPhone|iPad).*OS (\d+)_/i);
          if (match && match[2]) {
            const majorVersion = parseInt(match[2], 10);
            return majorVersion < 16;
          }
          return false;
        }

        if (checkForRedirect(clientId, location)) {
          window.top.location.href = `${process.env.REACT_APP_DASHBOARD_HOST_URI}/getting-started`;

          return {
            title: "",
            view: <></>,
          }
        } else if (isIosVersionPriorTo16(navigator.userAgent)) {
          // We cannot detect App version :( but we know for sure anyone prior to iOS 16 will be on an old app version (pre-IAS)
          // There may still be a small percentage of users on old app versions but newer iOS. However this will
          // 100% filter out all new installs from the App Store - i.e. new trialers, as the only way to get an old app version is to
          // be on an old iOS version
          return {
            title: "create-account-blocked",
            view: <AccountBlockOldAppPage props={req.params} />,
          };
        } else {
          return {
            title: "create-account",
            view: <AccountCreationPage props={req.params} />,
          };
        }
      })
    ),
    "/login": withTrackedView(
      <Layout>
        <View />
      </Layout>,
      route((req) => {
        return {
          title: "login",
          view: <LoginPage props={req.params} />,
        };
      })
    ),
    "/forgot-password": withTrackedView(
      <Layout>
        <View />
      </Layout>,
      route({
        title: "forgot-password",
        view: <ForgotPasswordPage />,
      })
    ),
    // backwards-compat routes
    "/": redirect((req) => {
      return `/create-account${req.search ? req.search + "&location=iframe" : "?location=iframe"}`
    }),
    "/iframe": redirect((req) => {
      return `/create-account${req.search ? req.search + "&location=iframe" : "?location=iframe"}`
    }),
    "/app": redirect((req) => {
      return `/create-account${req.search ? req.search + "&location=app" : "?location=app"}`
    }),
  })
);
