import rollbar from "helpers/rollbar";
import { useState, useEffect, FormEvent, ChangeEvent } from "react";
import { useIsMountedRef } from "./useIsMountedRef";

export interface Errors {
  [key: string]: any;
}

export interface PasswordValidation {
  hasDigit: boolean;
  hasLower: boolean;
  hasUpper: boolean;
  hasSpecialChar: boolean;
  hasMinLength: boolean;
}

export function useForm<T>(
    initialState: T,
    callback: (values: T) => Promise<void>,
    validate: (values: T, pvm?: PasswordValidation) => Errors,
    resetOnSubmit?: boolean
) {
  const [values, setValues] = useState<T>(initialState);
  const [errors, setErrors] = useState<Errors>({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [passwordValidMap, setPasswordValidMap] = useState<PasswordValidation>({hasDigit:false, hasLower:false, hasMinLength:false,hasSpecialChar:false,hasUpper:false});
  const isMountedRef = useIsMountedRef();

  useEffect(() => {
    async function run(): Promise<void> {
      try {
        await callback(values);
        if (isMountedRef.current) {
          setIsSubmitting(false);
          if (resetOnSubmit) {
            setValues(initialState);
          }
        }
      } catch (e) {
        rollbar.error(e);
        if (isMountedRef.current) {
          let message = "";
          if (e.response) {
            const response = JSON.parse(e.response);
            if (response.ModelState) {
              for (var error in response.ModelState) {
                if (typeof response.ModelState[error] !== "undefined") {
                  message = response.ModelState[error];
                }
              }
            } else {
              message = response.Message;
            }
          } else {
            message = e.message;
          }

          setErrors({ ...errors, api: message });
          setIsSubmitting(false);
        }
      }
    }

    if (Object.keys(errors).length === 0 && isSubmitting) {
      if (isMountedRef.current) {
        run();
      }
    } else {
      setIsSubmitting(false);
    }
  }, [
    errors,
    values,
    callback,
    initialState,
    isSubmitting,
    isMountedRef,
    resetOnSubmit,
  ]);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    if (event) event.preventDefault();
    setErrors(validate(values, passwordValidMap));
    setIsSubmitting(true);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    event.persist();
    if (event.target.id === "password") {
      setPasswordValidMap({
        hasDigit: event.target.value.match(/\d/i) !== null,
        hasUpper: event.target.value.match(/[A-Z]/g) !== null,
        hasLower: event.target.value.match(/[a-z]/g) !== null,
        hasSpecialChar: event.target.value.match(/[!@#$%^&*()_+=-]/i) !== null,
        hasMinLength: event.target.value.match(/.{8,}/i) !== null
      })
    }
    if (event.target instanceof HTMLInputElement && event.target.type === "checkbox") {
      const checkboxTarget = event.target;
      return setValues((values) => ({
        ...values,
        [event.target.id]: checkboxTarget.checked,
      }));
    } else {
      return setValues((values) => ({
        ...values,
        [event.target.id]: event.target.value,
      }));
    }
  };

  const hasErrors = () => Object.keys(errors).length > 0;

  return {
    handleChange,
    handleSubmit,
    isSubmitting,
    values,
    errors,
    hasErrors,
    passwordValidMap
  };
}
