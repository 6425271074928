import { useIsMountedRef } from "hooks/useIsMountedRef";
import React, { useEffect } from "react";
import { Notification, NotificationType } from "store/notification/types";
import styles from "./NotificationPopover.module.scss";

const DEFAULT_FADE = 2500;

interface NotificationPopoverProps {
  /** The notification. */
  notification: Notification;
  /** A handler function attached to the notification close button. */
  close: (id: number) => void;
}

/** A notification popover. */
export const NotificationPopover: React.FC<NotificationPopoverProps> = ({
  notification,
  close,
}: NotificationPopoverProps) => {
  const isMountedRef = useIsMountedRef();

  // if a manual number is set, prefer that
  // Otherwise, errors do not auto-fade and
  // all other messages use DEFAULT_FADE
  let fade = notification.fadeMilliseconds ? notification.fadeMilliseconds
    : notification.type === NotificationType.Danger ? -1 : DEFAULT_FADE;

  useEffect(() => {
    if (isMountedRef.current && fade > -1) {
      setTimeout(() => close(notification.id), fade);
    }
  }, [isMountedRef, notification, close, fade]);

  return (
    <>
      <div className="row justify-content-center">
        <div
          className={`alert alert-${notification.type} alert-dismissible fade show`}
          role="alert"
        >
          {notification.message}
          {
            !!notification.clickText && !!notification.clickAction
              ? <div onClick={() => notification.clickAction()}
                className={styles["click-action"]}>
                {notification.clickText}
              </div>
              : null
          }
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
            onClick={() => close(notification.id)}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
    </>
  );
};
