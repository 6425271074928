import { ShopifyAppClient } from "./ShopifyAppClient";
import { SilverSunnDashboardClient } from "./SilverSunnDashboardClient";
import { SwitcherAppClient } from "./SwitcherAppClient";
import { IAccountsProjectClient, RenderLocation, RenderMode, RenderModeOnSubmitParams, SupportedClient } from "./types";

export class AccountsProjectClient implements IAccountsProjectClient {
  public clientId: SupportedClient;
  public renderMode: RenderMode;
  public appWrapperClass: string;
  public onFormSubmit: ({ authCode, returnScheme }: RenderModeOnSubmitParams) => void;
  public formIsStyled: boolean;
  public minimizeFormContent: boolean;

  constructor (clientId: SupportedClient, location: RenderLocation) {
    const getClient = () => {
      switch(clientId) {
        case 'Switcher':
          return new SwitcherAppClient();
        case 'SilverSunnDashboard':
          return new SilverSunnDashboardClient(location === 'iframe' ? 'iframe' : 'topFrame');
        case 'ShopifyApp':
          return new ShopifyAppClient();
        default:
          return location === "app"
            ? new SwitcherAppClient()
            : new SilverSunnDashboardClient('iframe');
      }
    }

    const client = getClient();
    this.clientId = client.clientId;
    this.renderMode = client.renderMode;
    this.appWrapperClass = client.appWrapperClass;
    this.formIsStyled = client.formIsStyled;
    this.onFormSubmit = client.onFormSubmit;
    this.minimizeFormContent = client.minimizeFormContent;
  }
}