import i18n from "i18next";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NotificationState, AddNotificationPayload } from "./types";

export const notification = createSlice({
  name: "notification",
  initialState: {
    notifications: [],
    lastId: 0,
  } as NotificationState,
  reducers: {
    addNotification: (
      state,
      { payload }: PayloadAction<AddNotificationPayload>
    ) => {
      payload.message = i18n.t(payload.message, payload.messageOptions ? payload.messageOptions : undefined);
      if (payload.hasOwnProperty("clickText")) payload.clickText = i18n.t(payload.clickText);
      state.notifications.push({ ...payload, id: ++state.lastId });
      if (state.notifications.length > 1) {
        state.notifications.pop();
      }
    },
    removeNotification: (state, { payload }: PayloadAction<number>) => {
      state.notifications = state.notifications.filter((n) => n.id !== payload);
    },
  },
});
export const { addNotification, removeNotification } = notification.actions;

export default notification.reducer;
