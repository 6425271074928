import React from 'react';
import styles from "../AccountCreationForm.module.scss";
import { ReactComponent as SwitcherLogoSmall } from '../../../assets/icons/switcher-logo-small.svg';
import { useTranslation } from 'react-i18next';

export const LogoAndPrompt: React.FC<{ prompt: string }> = ({ prompt }) => {
  const { t } = useTranslation();
  return (
  <>
    <div className={styles["switcher-logo-wrapper"]}>
      <SwitcherLogoSmall />
    </div>
    <p className={styles["login-prompt"]}>
      {t(prompt)}
    </p>
  </>
)};