import React, { useCallback } from "react";
import { useForm, Errors } from "hooks/useForm";
import styles from "../forms/AccountCreationForm.module.scss";
import { Link, useCurrentRoute } from "react-navi";
import { LoginInput } from "../text-input/LoginInput";
import { client } from "api/client";
import { NotificationType } from "store/notification/types";
import { addNotification } from "store/notification/slice";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { LogoAndPrompt } from "components/forms/shared/LogoAndPrompt";
import { useGetHostClient } from "hooks/useGetHostClient";

export const ForgotPasswordPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { url } = useCurrentRoute();
  const onSubmit = useCallback(
    async (values) => {
      await client.account_ForgotPassword({ Email: values.email });
      dispatch(
        addNotification({
          type: NotificationType.Info,
          message: "messages:check-inbox"
        })
      );
    },
    [dispatch]
  );

  const { values, errors, isSubmitting, handleChange, handleSubmit } = useForm<{
    email: string;
  }>(
    {
      email: "",
    },
    onSubmit,
    (values) => {
      let errors: Errors = {};
      if (!values.email) {
        errors.email = t("forgot-password:enter-email");
      } else if (!/\S+@\S+\.\S+/.test(values.email)) {
        errors.email = t("errors:invalid-email");
      }

      return errors;
    }
  );

  const { hostClient } = useGetHostClient();

  return (
    <div className={styles["content-wrapper"]}>
      <form onSubmit={handleSubmit} className={styles["login-form"]} id={styles["forgot-password-form"]}>
        {hostClient.formIsStyled && (
          <LogoAndPrompt prompt="forgot-password:email-prompt" />
        )}
        <LoginInput
          label={t("create-account:email-address")}
          type="email"
          id="email"
          value={values.email}
          onChange={handleChange}
        />
        {errors.api && (
          <div className="alert alert-danger" role="alert">
            {errors.api}
          </div>
        )}
        <button
          type="submit"
          className="btn btn-white md-btn btn-block mb-4"
          id="forgot-password-submit"
          disabled={isSubmitting}
        >
          {t("buttons:reset-password").toUpperCase()}
        </button>
        {hostClient.formIsStyled && (
          <div className={`text-center ${styles["legal-text"]}`} id={styles["forgot-password-legal-text"]}>
            <Link className={`text-center ${styles['login-links']}`} href={`/login${url.search}`}>
              {t("buttons:back-to-login").toUpperCase()}
            </Link>
          </div>
        )}
      </form>
      {!hostClient.formIsStyled && (
        <div className={`text-center ${styles["legal-text"]}`} id={styles["forgot-password-legal-text"]}>
          <Link className={`text-center ${styles['login-links']}`} href={`/login${url.search}`}>
            {t("buttons:back-to-login").toUpperCase()}
          </Link>
        </div>
      )}
    </div>
  );
};
