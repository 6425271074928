
export interface NotificationState {
  notifications: Notification[];
  lastId: number;
}

export interface Notification {
  id: number
  message: string
  type: NotificationType
  messageOptions?: Object
  fadeMilliseconds?: number
  clickText?: string
  clickAction?: Function
}

export type AddNotificationPayload =  Omit<Notification, 'id'>;

export enum NotificationType {
  Success = 'success',
  Info = 'info',
  Danger = 'danger'
}
