import { Matcher, withView } from "navi";
import { Context } from "vm";

export function bootSegment(opts?: SegmentAnalytics.SegmentOpts) {
  window.intercomSettings = { hide_default_launcher: true };
  const segmentOptions = filterSegmentIntegrations(opts);
  analytics.load(process.env.REACT_APP_SEGMENT_WRITEKEY, segmentOptions);
  analytics.ready(function() {
    
    if (typeof ga !== "function") {
      return;
    }
    ga('require', 'linker');
    ga('linker:autoLink', ['switcherstudio.com']);
  });
}

export function trackEvent(event: string, properties?: any, options?: any) {
  const promise = new Promise<void>((resolve) => {
    analytics.track(event, properties, options, () => resolve());
  });

  return promise;
};

function parsePath(path: string) {
  let pieces = path.replace(/\//g, '').split('-');
  pieces = pieces.map(p => p.charAt(0).toUpperCase() + p.slice(1));

  let final = pieces.join(' ');
  return final;
}

// for non-empty redirects this will double-track in segment. Left unsolved since dashboard routing is unlikely to need serious redirects
export function trackPage(route: any, view: any) {
  if (typeof route !== 'function' && route.originalUrl !== '/') {
    const title = parsePath(route.originalUrl);

    analytics.page(route.originalUrl, {
      name: route.originalUrl,
      title: title
    });
  }

  return view();
};

export function withTrackedView(
  maybeResolvableView: any,
  child?: Matcher<Context>,
  exact?: boolean,) {
  return withView(
    (route: any) => trackPage(route, () => maybeResolvableView),
    child,
    exact
  );
}

function filterSegmentIntegrations(opts: SegmentAnalytics.SegmentOpts = {}) {
  const isDashboard: boolean = window.location.pathname === "/iframe";
  const blockedIntegrations: string[] = isDashboard
    ? []
    : ["Facebook Pixel", "Bing Ads", "Google Ads"];
  const blockedIntegrationOptions: Record<
    string,
    boolean
  > = blockedIntegrations.reduce<Record<string, boolean>>(
    (integrations, blockedIntegration) => {
      integrations[blockedIntegration] = false;
      return integrations;
    },
    {}
  );

  opts.integrations = {
    ...opts.integrations,
    ...blockedIntegrationOptions,
  };

  return opts;
}