import React, {useState} from "react";
import styles from "./LoginInput.module.scss";
import { ReactComponent as WorkIcon } from "assets/icons/work.svg";

interface TextInputProps {
  label?: string;
  type: string;
  id: string;
  placeholder?: string;
  value: string | number | string[] | undefined;
  error?: string;
  horizontal?: boolean;
  readonly?: boolean;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const TextInput: React.FC<TextInputProps> = ({
  label,
  type,
  id,
  placeholder,
  value,
  error,
  horizontal,
  readonly,
  disabled = false,
  onChange
}: TextInputProps) => {
  const [isFocused, setIsFocused] = useState(false);
  return (
    <>
      <div className={`form-group ${horizontal ? "row" : ""} ${styles['login-input']}`}>
        <div className={`${horizontal ? "col-xl-10" : ""}`}>
          <div className={`${styles['input-container']} ${error ? styles['input-container--error'] : ""}`}>
            <label
              className={`${horizontal ? "col-xl-2 col-form-label" : ""} ${styles['floating-label']} ${!!value || isFocused ? styles['has-focus'] : ''}`}
              htmlFor={id}
            >
              {label}
            </label>
            <WorkIcon className={styles['work-icon']} />
            <input
              type={type}
              className="form-control"
              id={id}
              value={value || ""}
              onChange={onChange}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              readOnly={readonly}
            />
          </div>
        </div>
        <small
          id={`${id}-help`}
          aria-describedby={id}
          className="form-text text-danger"
        >
          {error}
        </small>
      </div>
    </>
  );
};
