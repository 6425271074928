import { ClientRenderMode, RenderMode, RenderModeOnSubmitParams } from "clients/types";
export class AppRenderMode implements ClientRenderMode {
  public renderMode: RenderMode;
  public appWrapperClass: string;
  public minimizeFormContent: boolean;
  public onFormSubmit: ({ authCode, returnScheme }: RenderModeOnSubmitParams) => void;

  constructor (isFormStyled: boolean) {
    this.renderMode = 'app';
    this.appWrapperClass = `App__app${isFormStyled ? ' styled' : ''}`;
    this.minimizeFormContent = false;

    this.onFormSubmit = ({ authCode, returnScheme }) => {
       document.location.href = `${returnScheme}:?authorization_code=${authCode}`;
    }
  }
}