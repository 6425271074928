import React from "react";
import styles from "./AccountCreationForm.module.scss";
import { AccountCreationWrapperProps } from "./types";
import { AccountCreationForm } from "./AccountCreationForm";

export const WebAccountCreationFormWrapper: React.FC<AccountCreationWrapperProps> = ({
  handleSubmit,
  handleChange,
  handleCouponCode,
  removeCoupon,
  isSubmitting,
  showLoginBtn,
  resellerInventoryItem,
  referrer,
  values,
  errors,
  passwordValidMap,
}) => (
    <>
        <AccountCreationForm
            handleSubmit={handleSubmit}
            handleChange={handleChange}
            handleCouponCode={handleCouponCode}
            removeCoupon={removeCoupon}
            isSubmitting={isSubmitting}
            showLoginBtn={showLoginBtn}
            resellerInventoryItem={resellerInventoryItem}
            referrer={referrer}
            values={values}
            errors={errors}
            passwordValidMap={passwordValidMap}
            styles={styles}
            formType="WEB"
        />
    </>
  );
