import React from "react";
import { Notification } from "store/notification/types";
import { NotificationPopover } from "./NotificationPopover";
import styles from "./NotificationContainer.module.scss";

interface NotificationContainerProps {
  /** A list of notifications. */
  notifications: Notification[];
  /** A value which determines if notifications should be shown. */
  visible: boolean;
  /** A handler function attached to each of the notification popovers' close buttons. */
  close: (id: number) => void;
  /** A value which determines the notification container's position attribute. */
  fixed?: boolean;
}

/** A container element for notification popovers. */
export const NotificationContainer: React.FC<NotificationContainerProps> = ({
  notifications,
  visible,
  close,
  fixed = true,
}) => {
  return (
    <div
      className={`${styles["notification-container"]} ${fixed ? styles["fixed"] : ""
        } ${visible ? styles["show"] : ""} container`}
    >
      {notifications.map((n) => {
        return (
          <div key={n.id}>
            <NotificationPopover notification={n} close={close} />
          </div>
        );
      })}
    </div>
  );
};
