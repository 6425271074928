import { AccountsProjectClient } from "clients/AccountsProjectClient";
import { RenderLocation, SupportedClient } from "clients/types";
import { useMemo, useState } from "react";
import { useSearchParams } from "./useSearchParams";

export const useGetHostClient = () => {
  const searchParams = useSearchParams();
  const [ clientId ] = useState(searchParams.get("clientId") as SupportedClient);
  const [ location ] = useState(searchParams.get("location") as RenderLocation);
  const client = useMemo(() => new AccountsProjectClient(clientId, location), [clientId, location]);

  return { hostClient: client };
}