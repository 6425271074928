import { PasswordValidation } from "hooks/useForm";
import { TFunction } from "react-i18next";
import styles from "./PasswordRulesTooltip.module.scss";

export interface PasswordRulesTooltipProps {
  t: TFunction<"translation">;
  display: boolean;
  passwordValidMap: PasswordValidation;
  toRight: boolean;
}

export const PasswordRulesTooltip: React.FC<PasswordRulesTooltipProps> = ({
  t,
  display,
  passwordValidMap,
  toRight,
}) => (
  <div style={{position: "relative"}}>
    <div
      className={`
      ${styles["password-rules"]} 
      ${display ? styles["is-open"] : ""} 
      ${toRight ? styles["right-arrow"] : ""}
    `}
    >
      <ul className={`${styles["password-list"]}`}>
        <li
          className={
            passwordValidMap.hasMinLength ? `${styles["li-checked"]}` : ""
          }
        >
          {t("create-account:password-min")}
        </li>
        <li
          className={passwordValidMap.hasLower ? `${styles["li-checked"]}` : ""}
        >
          {t("create-account:password-lower")}
        </li>
        <li
          className={passwordValidMap.hasUpper ? `${styles["li-checked"]}` : ""}
        >
          {t("create-account:password-upper")}
        </li>
        <li
          className={passwordValidMap.hasDigit ? `${styles["li-checked"]}` : ""}
        >
          {t("create-account:password-digit")}
        </li>
        <li
          className={
            passwordValidMap.hasSpecialChar ? `${styles["li-checked"]}` : ""
          }
        >
          {t("create-account:password-special")}
        </li>
      </ul>
    </div>
  </div>
);
