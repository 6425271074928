import { ClientRenderMode, RenderMode, RenderModeOnSubmitParams } from "clients/types";

export class PopFrameRenderMode implements ClientRenderMode {
  public renderMode: RenderMode;
  public appWrapperClass: string;
  public minimizeFormContent: boolean;
  public onFormSubmit: ({ authCode }: RenderModeOnSubmitParams) => void;

  constructor ({
    clientUri,
    isFormStyled,
  }:{
    clientUri: string,
    isFormStyled: boolean,
  }) {
    this.renderMode = 'popFrame';
    this.appWrapperClass = `App__pop-frame${isFormStyled ? ' styled' : ''}`;
    this.minimizeFormContent = true;

    this.onFormSubmit = ({ authCode }: RenderModeOnSubmitParams) => {
      window.opener.postMessage(
        JSON.stringify({
          type: "login",
          payload: {
            code: authCode,
          },
        }),
        clientUri,
      );
    }
  }
}