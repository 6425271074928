import React from 'react';

export const Checkbox = ({
    checked,
    onCheck,
    description,
}: {
    checked: boolean;
    onCheck: (checked: boolean) => void;
    description: string;
}) => {
    return (
        <div className="form-check">
            <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckDefault"
                checked={checked}
                onClick={() => onCheck(!checked)}
            />
            <label className="form-check-label" htmlFor="flexCheckDefault">
                {description}
            </label>
        </div>
    )
}