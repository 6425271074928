import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { ChangeEvent, useCallback } from "react";
import { E164Number } from "libphonenumber-js/types";
import styles from "./index.module.scss";

export interface PhoneNumberInputChangeEvent {
    target: { value: E164Number; type: "tel"; id: string; checked?: boolean };
    persist?: () => void;
}

export interface PhoneNumberInputProps {
    id: string;
    label: string;
    placeholder: string;
    value: string;
    error?: string;
    help?: string;
    horizontal?: boolean;
    readonly?: boolean;
    disabled?: boolean;
    maxLength?: number;
    readOnly?: boolean;
    onChange?: (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
}

export const PhoneNumberInput: React.FC<PhoneNumberInputProps> = ({
    placeholder,
    value,
    label,
    id,
    readOnly = false,
    onChange,
    error
}) => {
    const _onChange = useCallback(
        (value?: E164Number) => {
            const event = {
                target: {
                    value,
                    id,
                    type: "tel"
                },
                persist: () => {}
            } as unknown as ChangeEvent<HTMLInputElement>;
            onChange(event);
        },
        [onChange, id]
    );

    return (
        <div className={`${styles["container"]} ${styles["create-account"]}`}>
            <label
                className={`${styles["floating-label"]} ${styles["has-focus"]}`}
                htmlFor={id}
            >
                {label}
            </label>
            <span className="form-control">
                <PhoneInput
                    id={id}
                    value={value}
                    onChange={_onChange}
                    placeholder={placeholder}
                    disabled={readOnly}
                    defaultCountry="US"
                />
            </span>
            <small
                id={`${id}-help`}
                aria-describedby={id}
                className="form-text"
            >
                {error && (
                    <div className="alert alert-danger alert-info mb-0 p-2">
                        {error}
                    </div>
                )}
            </small>
        </div>
    );
};
