import { ClientRenderMode, RenderMode, RenderModeOnSubmitParams } from "clients/types";

export class TopFrameRenderMode implements ClientRenderMode {
  public renderMode: RenderMode;
  public appWrapperClass: string;
  public onFormSubmit: ({ authCode, returnScheme }: RenderModeOnSubmitParams) => void;
  public minimizeFormContent: boolean;

  constructor ({
    clientUri,
    isFormStyled,
  }: {
    clientUri: string;
    isFormStyled: boolean;
  }) {
    this.renderMode = 'topFrame';
    this.appWrapperClass = `App__top-frame${isFormStyled ? ' styled' : ''}`;
    this.minimizeFormContent = false;

     this.onFormSubmit = ({ authCode }: RenderModeOnSubmitParams) => {
      window.location.assign(`${clientUri}?code=${authCode}`);
    }
  }
}