import { AppRenderMode } from "./renderModes/AppRenderMode";
import { IAccountsProjectClient, RenderMode, RenderModeOnSubmitParams, SupportedClient } from "./types";

export class SwitcherAppClient implements IAccountsProjectClient {
  public clientId: SupportedClient;
  public renderMode: RenderMode;
  public formIsStyled: boolean;
  public appWrapperClass: string;
  public minimizeFormContent: boolean;
  public onFormSubmit: ({ authCode, returnScheme }: RenderModeOnSubmitParams) => void;

  constructor () {
    this.clientId = 'Switcher';
    this.formIsStyled = false;
    const renderModeObject = new AppRenderMode(this.formIsStyled);
    this.appWrapperClass = renderModeObject.appWrapperClass;
    this.renderMode = renderModeObject.renderMode;
    this.onFormSubmit = renderModeObject.onFormSubmit;
    this.minimizeFormContent = renderModeObject.minimizeFormContent;
  }
}