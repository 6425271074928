import { IFrameRenderMode } from "./renderModes/IFrameRenderMode";
import { TopFrameRenderMode } from "./renderModes/TopFrameRenderMode";
import { IAccountsProjectClient, RenderMode, RenderModeOnSubmitParams, SupportedClient } from "./types";

export class SilverSunnDashboardClient implements IAccountsProjectClient {
  public clientId: SupportedClient;
  public renderMode: RenderMode;
  public formIsStyled: boolean;
  public appWrapperClass: string;
  public onFormSubmit: ({ authCode, returnScheme }: RenderModeOnSubmitParams) => void;
  public minimizeFormContent: boolean;

  constructor (renderMode: RenderMode) {
    this.clientId = 'SilverSunnDashboard';
    // Dashboard can be either topFrame or iframe render mode
    // Currently iframes are always unstyled
    const isTopFrame = renderMode === 'topFrame';
    this.formIsStyled = isTopFrame;
    const renderModeObject = isTopFrame
      ? new TopFrameRenderMode({ clientUri: process.env.REACT_APP_WEB_HOST_URI, isFormStyled: this.formIsStyled })
      : new IFrameRenderMode({ clientUri: process.env.REACT_APP_WEB_HOST_URI, isFormStyled: this.formIsStyled });
    this.appWrapperClass = renderModeObject.appWrapperClass;
    this.renderMode = renderModeObject.renderMode;
    this.onFormSubmit = renderModeObject.onFormSubmit;
    this.minimizeFormContent = renderModeObject.minimizeFormContent;
  }
}