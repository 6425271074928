import React from "react";
import styles from "./Loading.module.scss";
import LoadingAnimation from "./LoadingAnimation";

interface LoadingProps {
  /** A value which determines if the loading element is visible. */
  isLoading: boolean;
}

/** A loading display element. */
const Loading: React.FC<LoadingProps> = ({ isLoading }: LoadingProps) => (
  <div
    className={`${styles["loading-container"]} ${isLoading ? styles["active"] : ""
      }`}
  >
    <LoadingAnimation size={"lg"} />
  </div>
);

export default Loading;
