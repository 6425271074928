import Rollbar from "rollbar"

const globalEnvironment = process.env.REACT_APP_ENV || "local";
const globalAppVersion = process.env.REACT_APP_VERSION || "1.0";

const rollbar = new Rollbar({
  accessToken: "895a4e0bfb5a4379b2317eeb5d99a2cd",
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: globalEnvironment === 'local' ? false : true,
  environment: globalEnvironment,
  payload: {
      environment: globalEnvironment,
      client: {
          javascript: {
              code_version: globalAppVersion,
              source_map_enabled: true,
              guess_uncaught_frames: true
          }
      }
  }
});

export default rollbar;