import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "store/store";
import { LoginForm } from "../forms/LoginForm";
import { getToken } from "store/user/thunks";
import { IUserLogin } from "store/user/types";
import { client } from "api/client";
import { useGetHostClient } from "hooks/useGetHostClient";

interface LoginPageProps {
  props?: Record<string, string>;
}

export const LoginPage: React.FC<LoginPageProps> = ({ props }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { hostClient } = useGetHostClient();

  const onSubmit = useCallback(
    async (form: IUserLogin) => {
      await dispatch(getToken({ username: form.username, password: form.password }));
      const code = await client.account_GetAuthorizationCode(
        props?.clientId || "SilverSunnDashboard"
      );

      hostClient.onFormSubmit({ authCode: code });

    },
    [dispatch, props, hostClient]
  );

  return <LoginForm onSubmit={onSubmit} />;
};
