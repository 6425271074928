import React from "react";
import styles from "./AccountCreationForm.module.scss";
import { AccountCreationWrapperProps } from "./types";
import { AccountCreationForm } from "./AccountCreationForm";

export const AppAccountCreationFormWrapper: React.FC<AccountCreationWrapperProps> = ({
  handleSubmit,
  handleChange,
  isSubmitting,
  showLoginBtn,
  values,
  errors,
  passwordValidMap,
}) => (
    <>
        <AccountCreationForm
            handleSubmit={handleSubmit}
            handleChange={handleChange}
            isSubmitting={isSubmitting}
            showLoginBtn={showLoginBtn}
            values={values}
            errors={errors}
            passwordValidMap={passwordValidMap}
            styles={styles}
            containerClass="mt-0"
            formType="APP"
        />
    </>
  );
