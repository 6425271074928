import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { tryGetProp } from "helpers/utils";
import { Banner } from "components/banner/Banner";

const AccountBlockOldAppPage: React.FC<any> = ({ props }) => {
  const { t, i18n } = useTranslation();
  const lang = tryGetProp(props, "lang");

  useEffect(() => {
    if (lang) i18n.changeLanguage(lang);
  }, [lang, i18n]);

  return (
    <Banner header={t('create-account-blocked:header')} subheader={t('create-account-blocked:subheader')} doubleHeight icon="warning"/>
  );
};

export default AccountBlockOldAppPage;
