import { combineReducers } from "@reduxjs/toolkit";
import user from "./user/slice";
import loading from "./loading/slice";
import notification from "./notification/slice";

const rootReducer = combineReducers({
  user,
  loading,
  notification,
});

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;
