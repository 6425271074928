import React, { useState, useEffect, useCallback } from "react";
import styles from "./LoginInput.module.scss";
import { ReactComponent as AddCouponIcon } from "assets/icons/add.svg";
import { useTranslation } from "react-i18next";

interface CouponInputProps {
  label?: string;
  type: string;
  id: string;
  couponCode: string;
  addCoupon?: (value: string) => void;
  removeCoupon?: () => void;
}

export const CouponInput: React.FC<CouponInputProps> = ({
  label,
  type,
  id,
  couponCode,
  addCoupon,
  removeCoupon,
}: CouponInputProps) => {
  const { t } = useTranslation();
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [value, setValue] = useState<string>(couponCode || "")

  const onIconClick = useCallback(() => {
    if (couponCode) {
      removeCoupon();
      setIsFocused(false);
    } else if (!isFocused) {
      setIsFocused(true);
    } else if (value === undefined || value === "") {
      setIsFocused(false);
    } else {
      setIsFocused(true);
    }
  }, [couponCode, isFocused, removeCoupon, value]);

  useEffect(() => {
    setValue(couponCode)
    if (couponCode) {
      setIsFocused(true)
    }
  }, [couponCode])

  const handleCouponKeyboardEnter = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      addCoupon(value);
    }
  }, [addCoupon, value]);

  return (
    <>
      <div className={`form-group mb-4 ${styles['login-input']} ${styles['coupon-input']}`}>
        <div className={`${styles['input-container']} ${!isFocused || couponCode ? styles['not-focused'] : ''}`}>
          {
            !couponCode &&
            <label
              className={`${styles['floating-label']} ${isFocused ? styles['has-focus'] : ''}`}
              htmlFor={id}
            >
              {label}
            </label>
          }

          <AddCouponIcon
            className={`${styles['coupon-icon']} ${isFocused ? styles['remove-icon'] : ""}`}
            onClick={onIconClick}
          />

          <input
            type={type}
            className="form-control"
            id={id}
            value={value || ""}
            onChange={e => setValue(e.target.value)}
            onFocus={() => setIsFocused(true)}
            onKeyPress={handleCouponKeyboardEnter}
            readOnly={!!couponCode}
          />
          <button
            type="button"
            className={`btn btn-white md-btn mb-2`}
            style={!couponCode && isFocused ? { opacity: 1 } : { opacity: 0 }}
            disabled={value === '' || value === undefined}
            onClick={() => addCoupon(value)}
          >
            {t("buttons:redeem").toUpperCase()}
          </button>
        </div>
      </div>
    </>
  );
};
