import { PopFrameRenderMode } from "./renderModes/PopFrameRenderMode";
import { IAccountsProjectClient, RenderMode, RenderModeOnSubmitParams, SupportedClient } from "./types";

export class ShopifyAppClient implements IAccountsProjectClient {
  public clientId: SupportedClient;
  public renderMode: RenderMode;
  public formIsStyled: boolean;
  public appWrapperClass: string;
  public onFormSubmit: ({ authCode, returnScheme }: RenderModeOnSubmitParams) => void;
  public minimizeFormContent: boolean;

  constructor () {
    this.formIsStyled = true;
    this.clientId = 'ShopifyApp';
    const renderModeObject = new PopFrameRenderMode({ clientUri: process.env.REACT_APP_SHOPIFY_WEB_HOST_URI, isFormStyled: this.formIsStyled });
    this.appWrapperClass = renderModeObject.appWrapperClass;
    this.renderMode = renderModeObject.renderMode;
    this.onFormSubmit = renderModeObject.onFormSubmit;
    this.minimizeFormContent = renderModeObject.minimizeFormContent;
  }
}