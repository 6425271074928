import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserState, ITicket, IUserInfo, GetUserOrgsPayload } from "./types";
import { getToken, getUserInfo, getUserOrgs, refreshToken, getDefaultUserOrg } from "./thunks";
import { OrganizationUserDefault } from "@switcherstudio/switcher-api-client";

// TODO: configure these somewhere (store?)

const initialState = {
  ticket: undefined,
  userInfo: undefined,
  userStreamDestinations: [],
  orgStreamDestinations: [],
  orgMaxStreamDestinations: undefined,
  orgMaxUsers: undefined,
  defaultStreamDestination: undefined,
  orgName: undefined,
  orgs: [],
  userFeatures: [],
  defaultOrg: undefined,
} as UserState;

export const user = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    logout: () => initialState,
    identify: (state, { payload }: PayloadAction<Object>) => {
      analytics.identify(state.userInfo?.UserId || '',
        {
          email: state.userInfo?.Email || '',
          username: state.userInfo?.Email || '',
          // avatar: getGravatarImageUrl(),
          plan: state.ticket?.activePlan || '',
          enterprise: state.userInfo?.OrganizationId ? true : false,
          ...payload
        });
    },
    setTicket: (state, { payload }: PayloadAction<ITicket>) => {
      state.ticket = payload
    },
  },
  extraReducers: {
    [getToken.fulfilled.type]: (state, { payload }: PayloadAction<ITicket>) => {
      state.ticket = payload;
    },
    [getUserInfo.fulfilled.type]: (state, { payload }: PayloadAction<IUserInfo>) => {
      state.userInfo = payload;
    },
    [getUserOrgs.fulfilled.type]: (state, { payload }: PayloadAction<GetUserOrgsPayload>) => {
      const { orgs, maximumDestinations, maximumUsers, name } = payload;
      state.orgs = orgs;
      state.orgMaxStreamDestinations = maximumDestinations;
      state.orgMaxUsers = maximumUsers;
      state.orgName = name;
    },
    [refreshToken.fulfilled.type]: (state, { payload }: PayloadAction<ITicket>) => {
      state.ticket = payload
    },
    [getDefaultUserOrg.fulfilled.type]: (state, { payload }: PayloadAction<OrganizationUserDefault>) => {
      state.defaultOrg = payload
    },
    [getToken.rejected.type]: (_, { error }) => {
      throw error;
    },
    [getUserInfo.rejected.type]: (_, { error }) => {
      throw error;
    },
    [getUserOrgs.rejected.type]: (_, { error }) => {
      throw error;
    },
    [refreshToken.rejected.type]: (_, { error }) => {
      throw error;
    },
    [getDefaultUserOrg.rejected.type]: (_, { error }) => {
      throw error;
    }
  }
});

export const {
  logout,
  identify,
  setTicket
} = user.actions;

export default user.reducer;
