import { ITicket, IUserLogin, GetUserOrgsPayload } from "./types";
import { createAsyncThunk } from "@reduxjs/toolkit";

const apiBaseUrl = process.env.REACT_APP_API_URL || "https://silversunnapi-develop.azurewebsites.net";

export const getToken = createAsyncThunk(
  "users/getToken",
  async ({ username, password }: IUserLogin) => {
    const formData = new URLSearchParams();

    formData.append("grant_type", "password");
    formData.append("username", username);
    formData.append("password", password);
    formData.append("client_id", "SilverSunnDashboard");
    
    const tokenRes = await fetch(
      apiBaseUrl + "/Token",
      {
        method: "POST",
        body: formData,
      }
    );
    const text = await tokenRes.text();
    if (!tokenRes.ok) {
      throw new Error(text);
    }

    const ticket = JSON.parse(text) as ITicket;
    return ticket;
  }
);

export const getUserInfo = createAsyncThunk("users/getUserInfo", async () => {
  const { client } = await import("api/client");
  const userInfo = await client.account_GetUserInfo();
  
  return userInfo;
});

export const getUserOrgs = createAsyncThunk(
  "users/getUserOrgs",
  //TODO: DON'T USE ANY - creatLoadingThunk needs proper generics
  async (_, { getState }: any) => {
    const { client } = await import("api/client");
    const state = getState();
    const orgs = await client.userOrganizations_Index(
      state.user.ticket?.userId || ""
    );
    const payload: GetUserOrgsPayload = { orgs };

    if (state.user.userInfo?.OrganizationId) {
      const org = await client.organizations_GetOrganization(
        state.user.userInfo?.OrganizationId
      );
      payload.maximumDestinations = org.MaximumDestinations || 3;
      payload.maximumUsers = org.MaximumUsers || 10;
      payload.name = org.Name || "";
    }

    return payload;
  }
);

export const refreshToken = createAsyncThunk("users/refreshToken",
  async (_, { getState }: any) => {
    const tokenUrl = apiBaseUrl + "/Token";
    const state = getState();
    const formData = new URLSearchParams();

    formData.append("grant_type", "refresh_token");
    formData.append("refresh_token", state.user.ticket.refresh_token);
    formData.append("client_id", "SilverSunnDashboard");

    const tokenRes = await fetch(tokenUrl, {
      method: "POST",
      body: formData
    });

    const text = await tokenRes.text();
    if (!tokenRes.ok) {
      throw new Error(text);
    }

    const ticket = JSON.parse(text) as ITicket;
    return ticket;
  }
)

export const getDefaultUserOrg = createAsyncThunk("users/getDefaultUserOrg",
  async (_, { getState }: any) => {
    const { client } = await import("api/client");
    const state = getState();

    const defaultOrg = await client.userOrganizations_GetDefault(state.user.userInfo.UserId);

    return defaultOrg;
  }
);
