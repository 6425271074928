import { CouponResponseBillingProvider, ResellerInventoryItem } from "@switcherstudio/switcher-api-client";
import { client } from "api/client";
import { SupportedClient } from "clients/types";
import rollbar from "helpers/rollbar";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import { AppDispatch } from "store/store";

export const useHandleCoupon = (clientId: SupportedClient, codeFromProps: string) => {
  const dispatch = useDispatch<AppDispatch>();

  const [couponCode, setCouponCode] = useState(codeFromProps);

  const [resellerInventoryItem, setResellerInventoryItem] =
    useState<ResellerInventoryItem>({});

  const matchClientToBillingProvider = useCallback(
    (billingProvider: CouponResponseBillingProvider) => {
      if (clientId === "Switcher" || "SilverSunnDashboard") {
        return billingProvider === CouponResponseBillingProvider.Stripe;
      } else if (clientId === "ShopifyApp") {
        return billingProvider === CouponResponseBillingProvider.Shopfiy;
      }
    },
    [clientId]
  );

  const handleCouponCode = useCallback(
    async (code: string) => {
      if (code !== null) {
        try {
          const data = await client.coupons_CheckCouponCode(code);

          if (!matchClientToBillingProvider(data.BillingProvider)) {
            throw Error("Coupon billing provider does not match client id");
          }

          setResellerInventoryItem(data.ResellerInventoryItem);
          dispatch(
            addNotification({
              type: NotificationType.Success,
              message: "create-account:successfully-applied",
              messageOptions: { couponInput: code },
            })
          );
        } catch (e) {
          // invalid coupon
          rollbar.error(e);
          setCouponCode(null);
          dispatch(
            addNotification({
              type: NotificationType.Danger,
              message: "errors:invalid-coupon",
            })
          );
        }
      }
    },
    [dispatch, matchClientToBillingProvider]
  );

  useEffect(() => {
    handleCouponCode(couponCode);
  }, [couponCode, handleCouponCode]);

  const removeCoupon = () => {
    dispatch(
      addNotification({
        type: NotificationType.Info,
        message: "coupon-form:removed-coupon",
        messageOptions: { couponInput: resellerInventoryItem.CouponCode },
      })
    );
    setResellerInventoryItem({});
  };

  return { resellerInventoryItem, removeCoupon, handleCouponCode };
};
